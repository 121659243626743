/* eslint-disable no-bitwise */
/**
 * 工具方法生成sessionId，存储在sessionStorage，直接在网上找的算法
 * 防止处理代码出错暂时不转ts和忽略eslint处理位运算规则
 * @returns 返回sessionId
 */
import { getUrlParam } from './common';

export function getSessionID() {
  if (!window.sessionStorage) return '';
  const sessionIDKey = 'YB_SESSIONID';
  const sid = window.sessionStorage.getItem(sessionIDKey);
  if (sid) {
    return sid;
  }
  const busiTraceId = getUrlParam('busiTraceId');
  if (busiTraceId) {
    window.sessionStorage.setItem(sessionIDKey, busiTraceId);
    return busiTraceId;
  }
  const id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  window.sessionStorage.setItem(sessionIDKey, id);
  return id;
}
