/*
 * @Author: 朱晓真
 * @Date: 2022-09-05 10:42:39
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-08-29 11:27:28
 * @Description: 落地页路由
 */
import ProductDetail from './ProductDetail';

export default [
  {
    path: '/cargo',
    component: () => import('./productShow'),
    routes: [
      {
        path: '/cargo/index',
        component: () => import('./productShow'),
      },
      {
        path: '/cargo/detail',
        component: ProductDetail,
      },
      {
        path: '/cargo/cxDetail',
        component: ProductDetail,
      },
      {
        path: '/cargo/nonStandard',
        component: () => import('./Nonstandard'),
      },
      {
        path: '/cargo/protectdetail',
        component: () => import('./ProtectDetail'),
      },
      {
        path: '/cargo/seodetail',
        component: ProductDetail,
      },
      {
        path: '/cargo/bwdetail',
        component: ProductDetail,
      },
      {
        path: '/cargo/prodetail',
        component: () => import('./ProtocolDetail'),
      },
      {
        path: '/cargo/infodetail',
        component: () => import('./ChangXian'),
      },
      {
        path: '/cargo/calcFee',
        component: () => import('./ChangXian'),
      },
      {
        path: '/cargo/bankInfo',
        component: () => import('./ChangXian'),
      },
      {
        path: '/cargo/login',
        component: () => import('./PZLogin'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/cargo/combine',
        component: () => import('./Combine'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/cargo/couple',
        component: () => import('./coupleLink/Login'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/cargo/middlePage',
        component: () => import('./ChangXian/MiddlePage'),
      },
      {
        path: '/cargo/middle',
        component: () => import('./Middle'),
      },
      {
        path: '/cargo/coopdetail',
        component: () => import('./cooperation/cargo'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/cargo/jyProtocol',
        component: () => import('./jyProtocol'),
      },
      {
        path: '/cargo/multiple', // 多元业务投保页
        component: () => import('./Multiple'),
      },
    ],
  },
  {
    path: '/gift/detail',
    component: () => import('./ProductDetail'),
  },
  {
    path: '/gift/middle',
    component: () => import('./giftMiddle'),
  },
  {
    path: '/imagecollect',
    component: () => import('./ChangXian/ImageCollect'),
    title: '身份证件上传',
  },
  {
    path: '/sxcx',
    component: () => import('./cooperation/sxcx/Land'),
    routes: [
      {
        path: '/sxcx/detail',
        component: () => import('./cooperation/sxcx/Land'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/sxcx/calculate',
        component: () => import('./cooperation/sxcx'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/sxcx/info',
        component: () => import('./cooperation/sxcx'),
        registerOwnShare: {
          hideShare: true,
        },
      },
      {
        path: '/sxcx/imagecollect',
        component: () => import('./cooperation/sxcx/ImageCollect'),
      },
      {
        path: '/sxcx/result',
        component: () => import('./cooperation/sxcx/Result'),
      },
    ],
  },
  {
    path: '/producttype',
    component: () => import('./ProductType'),
    registerOwnShare: {
      shouxin: false,
      yuanbao: true,
    },
  },
  {
    path: '/groupPurchaseDetail',
    component: () => import('./insurance/groupPurchaseDetail/index.tsx'),
  },
  {
    path: '/familygetgift',
    component: () => import('./familyGetGift/index'),
  },
  {
    path: '/productdetail', // 均价2.0 优化单产品落地页
    component: () => import('./optimizeProductDetail'),
  },
  {
    path: '/notificationToProduct',
    component: () => import('./notificationToProduct'),
  },
];
