import React, { Fragment, useEffect, useState } from 'react';
import { render } from 'react-dom';
import { Provider as StoreProvider } from 'mobx-react';
import { createBrowserHistory } from 'history';
import stores from 'src/store';
import { openHrefUrl } from 'store/UrlTools';
import { autoAddHistory, autoBackHistory } from 'ybcommon/ybutils/common/backutil';
import { historyInit } from 'ybcommon/ybutils/historyUtil';
import { url, storage, UserUtil } from 'common/utils';
import { addUrlParams } from 'ybcommon/ybutils/common/common';
import { type as TypeUtil } from 'fe-yb-tools';
import { isWeMini, isAlipayClient, isWechat, isTTMini } from 'common/utils/device';
import { v1 as uuidv1 } from 'uuid';
import ybModal from 'fe-yb-modal';
import { init as wxApiInit } from 'ybcommon/wxApi';
import { setTgReferer, getMediaUuid } from 'ybcommon/ybmodel/tgUtil';
import { webpSupport } from 'ybcommon/webpCheck';

import App from './sys/app';
// 全局样式配置
import 'ybcommon/style/index.less';

window.ybParams = {};
// 设置全局的存储当前路径的变量
// window.__pathname = window.location.pathname;
console.log('version:', __VERSION__);
// webp图片格式兼容性判断
webpSupport();

// 生成uuid并存储到cookie
let _localUUID = UserUtil.getItem('__UUID__');
if (!_localUUID) {
  _localUUID = uuidv1();
}
UserUtil.setItem('__UUID__', _localUUID, true);
stores.userStore.uuid = _localUUID;

// 设置vConsole的位置为屏幕的左上角
if (!storage.get('vConsole_switch_x')) {
  storage.set('vConsole_switch_x', window.outerWidth);
  storage.set('vConsole_switch_y', window.outerHeight / 2);
}

/**
 * 小程序内h5逻辑处理
 * 带有isWeChatMiniApp，则为小程序环境，1：微信小程序；2：快手小程序；3：走路赚钱小程序（已无到主站入口）；4：抖音小程序；5：首信微信小程序
 * 可能带的参数：userId, userToken, isWeChatMiniApp为必带，1：appId，tuiaId，openId, unionId；2：ksOpenId, ksClue；3：appId，miniUserId；4：dyOpenId
 */
const params = url(window.location.href).params;
const { isWeChatMiniApp, shuntId = '' } = params;
const MINSTORAGEKEYS = '_MINIDATA_';
if (+isWeChatMiniApp > 0) {
  // 将参数更新到userStore
  Object.assign(stores.userStore, params);
  storage.set(MINSTORAGEKEYS, params);
} else {
  const minidata = storage.get(MINSTORAGEKEYS);
  // 有小程序内参数丢失的情况，故需从本地获取下数据，如果为小程序内，则更细到store
  if (minidata && (isWeMini() || isAlipayClient() || isTTMini())) {
    Object.assign(stores.userStore, minidata);
  }
}
stores.userStore.userId = UserUtil.getItem('userId');
stores.userStore.userToken = UserUtil.getItem('token');

const _needReplaceHosts = ['exchange.yuanbaobaoxian.com', 'exchange.shijiekaiyuan.com', 'exchange.hljjytkj.com', 'devexchange.yuanbaobaoxian.com', 'testexchange.yuanbaobaoxian.com'];

const { host, href } = window.location;
let _needReplaceHost;
for (let i = 0; i < _needReplaceHosts.length; i++) {
  const _tmp = _needReplaceHosts[i];
  if (_tmp === host) {
    _needReplaceHost = _tmp;
    break;
  }
}
// 新增全局添加 AB测分流shuntId逻辑
window.ybParams.shuntId = shuntId;

if (_needReplaceHost) {
  setTimeout(() => {
    let _url;
    if (__PUB__) {
      _url = href.replace(_needReplaceHost, 'exchange.yuanbaobaoxian.cn');
    } else if (_needReplaceHost === 'devexchange.yuanbaobaoxian.com') {
      _url = href.replace(_needReplaceHost, 'devexchange.yuanbaobaoxian.cn');
    } else if (_needReplaceHost === 'testexchange.yuanbaobaoxian.com') {
      _url = href.replace(_needReplaceHost, 'testexchange.yuanbaobaoxian.cn');
    }
    window.location.replace(_url);
  }, 0);
} else {
  setTimeout(() => {
    setTgReferer();
    const RootView = (props) => {
      const history = createBrowserHistory();
      const originPush = history.push;
      const originReplace = history.replace;
      // 添加额外参数统一处理
      const hisFunc = (histype) => (path, state) => {
        const originPath = path;
        let resPath = TypeUtil.isObject(originPath) ? originPath?.search : originPath;
        const channel = stores.userStore.channel;
        const mediaUuid = getMediaUuid();
        if (resPath && (channel || mediaUuid)) {
          resPath = addUrlParams(resPath, { channel, mediaUuid });
        }
        const curPath = TypeUtil.isObject(originPath) ? { ...originPath, search: resPath } : resPath;
        try {
          if (histype === 'push') {
            originPush(curPath, state);
          } else {
            originReplace(curPath, state);
          }
        } catch (error) {
          if (window.yb && window.yb.uplogCatchError) {
            window.yb.uplogCatchError(JSON.stringify({ code: 'historyError', msg: { message: error?.message || 'history异常', stack: error?.stack ? error.stack.split(':') : '' } }));
          }
        }
      };

      history.push = hisFunc('push');
      history.replace = hisFunc('replace');
      if (isWechat()) {
        wxApiInit();
      }
      window.addEventListener('popstate', () => stores.toolStore.popstateListener(), true);
      historyInit(history);
      ybModal.init({ // 内含路由监听注册方法 可通过ybModal.onBack设置自定义返回逻辑
        gotoH5: (link) => openHrefUrl(link),
        autoAddHistory,
        autoBackHistory,
      });
      // 设置全局的存储当前路径的变量，此处更新为优先从history获取，看是否可以减少pathname与地址栏链接不一致问题
      window.__pathname = history?.location?.pathname || window.location.pathname;
      return (
        <Fragment>
          <StoreProvider {...stores}>
            <App {...props} history={history} />
          </StoreProvider>
          <InspectorComponent />
        </Fragment>
      );
    };

    render(<RootView />, document.getElementById('app'));
  }, 0);
}

function InspectorComponent() {
  if (__DEV__) {
    const [result, setCom] = useState(null);

    useEffect(() => {
      import('react-dev-inspector').then(({ Inspector }) => {
        const inc = () => (
          <Inspector
            keys={['control', 'shift', 'c']}
            disableLaunchEditor={true}
            onClickElement={({ codeInfo }) => {
              const { lineNumber, columnNumber, relativePath } = codeInfo;
              // eslint-disable-next-line no-undef
              const absolutePath = `${__CWD__}/${relativePath}`;

              window.open(`vscode://file/${absolutePath}:${lineNumber}:${columnNumber}`);
            }}
          >
            {null}
          </Inspector>
        );
        setCom(inc);
      });
    }, []);

    return result;
  }

  return null;
}
