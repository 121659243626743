/*
 * @Author: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @Date: 2022-10-21 09:38:40
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-09-09 18:18:52
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/sys/people/route.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  path: '/people',
  routes: [
    {
      path: '/people/edit',
      component: () => import('./Edit'),
      title: '编辑资料',
    },
    {
      path: '/people/add',
      component: () => import('./Edit'),
      title: '添加家人',
    },
    {
      path: '/people/list',
      component: () => import('./List'),
      title: '我和家人',
    },
  ],
};
