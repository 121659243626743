import { observable, action, makeObservable } from 'mobx';
import { isAlipayClient, isWechat, isTTMini } from 'utils/device';
import { TianjiFetch, UCFetch } from 'fetch';
import { UserUtil } from 'utils';
import { Toast } from '@yb/fe-component';
import { getLocationSearch } from 'ybcommon/ybutils/common/common';
import { isShouxin } from 'ybcommon/ybutils/common/company';
import Dict from 'ybcommon/ybutils/dict';
import { setMediaUuid } from 'ybcommon/ybmodel/tgUtil';

export class UserStore {
  constructor() {
    makeObservable(this);
  }

  // 监听用户的字段信息

  @observable hasLogin; // 是否已经登录,undefined

  @observable userId; // 用户id

  @observable alipayUserId; // 支付宝用户id

  @observable headImg; // 用户头像

  @observable nickname; // 用户昵称

  @observable sex; // 用户性别 1:男 2:女

  @observable phone; // 用户手机号

  @observable hasInfo; // 是否有头像昵称 性别信息 0:没有 1:有

  @observable hasBindWx; // 是否绑定微信 0 没有 1 有

  @observable msgCount; // 消息数量

  @observable unionId; // 开发平台id

  @observable openId; // 去支付时候需要的openId

  @observable unReadNumCache = {}; // 对未读数量消息进行缓存

  @observable isSubscribe; // 是否关注公众号

  @observable isSubscribeYb; // 是否关注元保

  @observable isSubscribeMall; // 是否关注商城

  @observable jingjiAuthorize; // 是否授权经过账号查看享安订单

  @observable longTmpList = []; // 天机请求的长期模板信息

  @observable disposableTmpList = []; // 天机请求的一次性模板信息

  @observable longTmpIds = []; // 长期模板id

  @observable disposableTmpIds = []; // 一次性模板id

  @observable longTmpIdsStr = ''; // 长期模板id逗号分隔字符串

  @observable disposableTmpIdsStr = ''; // 一次性模板id逗号分隔字符串

  @observable cameraAuth = 1; // 相册授权 0 同意 1 不同意

  @observable appCameraAuth = 1; // app相机/相册权限 0-开启 1-关闭

  @observable personalizedRec = 0; // 个性化推荐状态 0-开启 1-关闭

  lastVisitTime = ''; // 用户上次访问时间  "YYYY-MM-DD HH:mm:ss"

  danmuType = 1; // 弹幕类型 1-普通弹幕 2-家庭弹幕，默认为1

  // 不进行监听的全局数据
  coreData = {};

  channel = ''; // 从浏览器地址获取的渠道，始终透传

  successAbTest = 1; // 投保成功页ab测

  stopRecorder = false; // 防止出现异常时，cdn脚本缓存更新慢，用接口控制异常情况下是否录制

  hasCoupon = 0; // 微信小程序抽奖活动 0 - 未领取，1- 领取未使用，2- 已使用

  notification = {}; // 首信客户告知书

  notification2 = {}; // 元保客户告知书

  notification3 = {}; // 白鸽保客户告知书

  protocolPage = {}; // 隐私协议 和 平台协议

  isWeChatMiniApp = 0; // 小程序来源 1 微信主站 2 快手 3 走路赚钱小程序 4 头条小程序 5 首信小程序 6 支付宝投诉退款通道 7支付宝投诉退款助手 8 支付宝投诉退款售后

  hasShowHalfScreen = 0; // 当前是否展示半屏弹框

  tuiaId = ''; // 微信小程序 推啊渠道推啊用户ID

  isLimitedCity = 0; // 是否被限制的城市（批增用）

  faceIdentification = 1; // 是否开启人脸识别, 0打开, 1关闭, 设计有点反人类

  isAbTestLimitCity = 0; // 是否ab限制城市（北京、深圳、中山、青岛）0-不是 1-是

  isHomeGifLimitCity = 0; // 我的页为家人领取赠入口限制 1-限制城市，不展示 0-非限制城市，展示

  @observable keyboardShowAndroid; // 监听android键盘是否弹起

  zhCorrectionInsurance = ''; // 所有中华的批增产品id

  internalChannelIds = ''; // 站内渠道

  zhCriticalIllnessInsurance = ''; // 中华重疾产品

  userIdencrypt = getLocationSearch(window.location.search)?.userIdencrypt || ''; // 用户加密id默认从路径上取

  applicantInsurantRelations = ['1', '2', '3', '4']; // 投被保人关系类型，其值从cms端的配置中获取，家财险会在cms端配置仅本人投保，即返回['1']

  /** 临时添加，在login页面登录成功后打点，次页1或者次页2请求结束后计算时间间隔并上报 */
  loginTsp = null;

  /** 临时添加，在middle页面打点，登录页、次页1或者次页2请求结束后计算时间间隔并上报 */
  middleTsp = null;

  /** 临时添加，在登录页获取完极验版本6后打点，极验6失败或者成功时计算时间间隔并上报 */
  jiYanTsp = null;

  // 是否跳转新版本客服链接
  isNewKefuLink = null;

  @action reset() {
    this.hasLogin = false;
    this.userId = null;
    this.lastVisitTime = '';
    this.alipayUserId = null;
    this.headImg = null;
    this.nickname = '';
    this.sex = -1;
    this.phone = null;

    this.hasInfo = 0;
    this.hasBindWx = 0;

    this.msgCount = 0;
    this.unionId = '';
    this.openId = '';
    this.isSubscribe = false;
    this.isSubscribeYb = false;
    this.isSubscribeMall = false;
    this.keyboardShowAndroid = false;
    this.jingjiAuthorize = 0;
    this.userId = UserUtil.getItem('userId');
    this.userToken = UserUtil.getItem('token');
    this.uuid = UserUtil.getItem('__UUID__');
  }

  @action updateCoreData(data, channel = '') {
    if (data) {
      const {
        /* dict, serverTime, */
        // env,
        user,
        danmuType,
        successAbTest,
        stopRecorder = false,
        notification,
        notification2,
        notification3,
        protocolPage,
        hasCoupon,
        jingjiAuthorize,
        mediaUuid,
        isLimitedCity,
        isHomeGifLimitCity,
        zhCorrectionInsurance,
        internalChannelIds,
        zhCriticalIllnessInsurance,
        isAbTestLimitCity,
        customerVersionSwitch,
      } = data;
      this.coreData = data;
      if (data.share) {
        try {
          this.coreData.share = JSON.parse(data.share);
        } catch (err) {
          this.coreData.share = {};
        }
      }
      // 设置shuntAppId
      // switch (env) {
      //   case 'dev1':
      //   case 'dev2':
      //   case 'test1':
      //   case 'test2':
      //     // 所有测试环境id一致
      //     this.shuntAppId = '1632814006';
      //     break;
      //   default:
      //     // 新增加的pre同线上
      //     this.shuntAppId = '1633685515';
      //     break;
      // }
      this.isAbTestLimitCity = isAbTestLimitCity || 0;
      this.isLimitedCity = isLimitedCity || 0;
      this.isHomeGifLimitCity = isHomeGifLimitCity || 0;
      Dict.data = data.dict;
      this.notification = notification || {};
      this.notification2 = notification2 || {};
      this.notification3 = notification3 || {};
      this.protocolPage = JSON.parse(protocolPage || '{}');
      this.zhCriticalIllnessInsurance = zhCriticalIllnessInsurance || '';
      this.danmuType = danmuType || 1;
      this.successAbTest = successAbTest || 1;
      this.stopRecorder = stopRecorder || false;
      this.hasCoupon = hasCoupon;
      this.jingjiAuthorize = jingjiAuthorize || 0;
      this.zhCorrectionInsurance = zhCorrectionInsurance;
      this.internalChannelIds = internalChannelIds;
      this.isNewKefuLink = customerVersionSwitch === 1;

      this.updateUserData(user, channel);
      if (mediaUuid) {
        setMediaUuid(mediaUuid);
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  @action updateUserData(user, channel) {
    if (user) {
      const { userId, lastVisitTime, headImg, nickName, sex, phone, openId, unionId, ybSubscribe, jjdomainSubscribe, alipayUserId, ifUpgrade = null, jingjiAuthorize, shouxinSubscribe, cameraAuth, headImgDefault, personalizedRec, appCameraAuth, faceIdentification } = user;
      this.userId = userId || '';
      this.lastVisitTime = lastVisitTime || '';
      this.headImgDefault = headImgDefault;// 头图是否是默认图片
      this.unionId = unionId;
      this.openId = openId;
      this.alipayUserId = alipayUserId;
      this.headImg = headImg;
      this.nickname = nickName;
      this.sex = sex || '';
      this.phone = phone;
      this.isSubscribeYb = +ybSubscribe === 1; // 是否关注了元保健康
      this.isSubscribeMall = +jjdomainSubscribe === 1; // 是否关注了经纪域名的公众号
      this.isSubscribe = +this.isWeChatMiniApp === 2 || +this.isWeChatMiniApp === 4 ? true : +jjdomainSubscribe === 1; // 快手、头条小程序默认已关注 // 关注元保经纪就算关注
      if (isShouxin()) {
        this.isSubscribe = +shouxinSubscribe === 1;
      }
      this.ifUpgrade = ifUpgrade || 0;
      this.userToken = this.isWeChatMiniApp ? this.userToken : UserUtil.getItem('token');
      this.jingjiAuthorize = +jingjiAuthorize === 1;
      this.faceIdentification = +faceIdentification ?? 1;
      this.cameraAuth = cameraAuth ? +cameraAuth : this.cameraAuth;
      this.personalizedRec = personalizedRec ? +personalizedRec : this.personalizedRec;
      this.appCameraAuth = appCameraAuth ? +appCameraAuth : this.appCameraAuth;
      this.updateUuid();
      if (isWechat() || isAlipayClient() || isTTMini()) {
        if (phone) {
          this.hasLogin = !!userId;
        } else {
          this.hasLogin = false;
        }
      } else {
        this.hasLogin = !!userId;
      }
    } else {
      this.hasLogin = false;
    }
    const yb = window.yb;
    if (yb) {
      yb.updateUserData({
        user_id: this.userId || '',
        request_id: this.uuid || '',
        isLogin: this.hasLogin ? 'login' : 'NotLogin',
      });
    }
  }

  @action updateApplicantInsurantRelations(newRelations) {
    if (Array.isArray(newRelations)) this.applicantInsurantRelations = newRelations;
  }

  /**
   * 更新store中用户授权字段的值
   * * @param {Object} res: 更新接口返回的数据
   * @param {Object} setting: { settingKey, settingValue }
   * settingKey: privacyAuth: 隐私授权状态 personalizedRec: 个性化推荐状态 cameraAuth: 小程序相机授权/相册授权 appCameraAuth: app相机授权/相册授权
   */
  @action updateUserSetting(res, setting) {
    const { settingKey, settingValue } = setting || {};
    if (settingKey) {
      this[settingKey] = res?.[settingKey] ? +res[settingKey] : settingValue;
    }
  }

  /**
   * 更新用户设置
   * @param {Object} setting: { settingKey, settingValue }
   * settingKey: privacyAuth: 隐私授权状态 personalizedRec: 个性化推荐状态 cameraAuth: 小程序相机授权/相册授权 appCameraAuth: app相机授权/相册授权
   */
  resetUserSetting(setting) {
    UCFetch.post('/api/setting/user/switch', setting).then((res) => {
      this.updateUserSetting(res, setting);
    }).catch((err) => {
      Toast.fail(err);
    });
  }

  /**
   * 更新uuid
   * 若缓存中有uuid，则进行赋值；若没有，则上报棱镜
   */
  updateUuid = () => {
    const storageUuid = UserUtil.getItem('__UUID__');
    if (storageUuid) {
      // 缓存中有uuid，给uuid赋值
      this.uuid = storageUuid;
    } else if (window.yb && window.yb.uplogCatchError) {
      // uuid缺失，上报棱镜
      window.yb.uplogCatchError(JSON.stringify({
        code: 'uuidError',
        msg: {
          message: 'uuid缺失',
          stack: '',
          originUuid: this.uuid,
          storageUuid,
        },
      }));
    }
  };

  /**
   * 天机异步请求 获取模板信息并处理关注逻辑
   * @returns
   */
  getSubscribeTemplates = () => TianjiFetch.post('tianji/exchange/substatus/listWechatPermissionBySubscribeStatus', {
    open_id: this.openId,
    yuanbaoSubStatus: this.isSubscribeYb ? 1 : 0,
    mallSubStatus: this.isSubscribeMall ? 1 : 0,
  })
    .then((result) => {
      if (result) {
        this.updateTemplateList(result);
      }
      return Promise.resolve();
    })
    .catch(() => Promise.resolve());

  /**
   * 更新模板信息
   * @param {Object} data
   */
  updateTemplateList(data) {
    if (data) {
      const { longTmpList = [], disposableTmpList = [] } = data;
      this.longTmpList = longTmpList || [];
      this.disposableTmpList = disposableTmpList || [];
      this.longTmpIds = this.longTmpList.map((item) => item.templateId);
      this.longTmpIdsStr = this.longTmpIds.join(',');
      this.disposableTmpIds = this.disposableTmpList.map((item) => item.templateId);
      this.disposableTmpIdsStr = this.disposableTmpIds.join(',');
    }
  }

  // 获取当前环境的openId 用户中心接口使用
  getOpenId() {
    return this.openId;
  }
}

const _store = new UserStore();
_store.reset();

export default _store;
