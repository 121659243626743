/*
 * @Author: 朱晓真
 * @Date: 2024-03-08 10:22:52
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2024-06-12 13:08:38
 * @Description: 加密信息工具类
 */
import stores from 'store';
import { getLocationSearch } from 'ybcommon/ybutils/common/common';

const { toolStore, userStore } = stores;
/**
* 是否是加密用户访问
*/
export function isEncryptUser() {
  return !userStore.hasLogin && userStore.userIdencrypt;
}

/**
* 是否是加密订单用户
*/
export function isEncryptOrderUser() {
  /** 加密订单id默认从路径上取 */
  const orderIdencrypt = getLocationSearch(window.location.search)?.orderIdencrypt || '';
  return !userStore.hasLogin && orderIdencrypt;
}

/**
* 是否是加密用户访问
*/
export function encryptUserToLogin() {
  if (isEncryptUser()) {
    toolStore.doLogin();
    return true;
  }
  return false;
}
